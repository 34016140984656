.disabled img {
  /* color: white; */
  -webkit-filter: grayscale(100%);
  -webkit-filter: brightness(100%);
  filter: grayscale(100%);
  filter: grayscale(100%) contrast(40%) brightness(135%);
}

.disabled {
  pointer-events: none;
}

.disabled p {
  color: rgb(163, 163, 163);
}
