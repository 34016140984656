.practice-form-container {
  position: relative;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* height: 100%; */
  margin: 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.practice-frequency-question {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 15px; */
}
/* .practice-frequency-question div,
span {
  color: var(--text-light-gray);
} */

.practice-form-container label {
  /* margin-bottom: 2px; */
  /* color: var(--text-light-gray); */
}

.practice-form-container input,
textarea,
select {
  border: solid rgb(205, 205, 205) 1px;
  border-radius: 5px;
  padding: 5px;
  background-color: transparent;
}
.practice-form-container input:focus,
textarea:focus,
select:focus {
  outline: 2px solid#4285F4;
  outline-offset: -1px;
}

#custom-category-input,
.practice-form-container select {
  margin-inline: 5px;
}

#repeat-count {
  width: 55px;
}

.practice-button {
  width: 90px;
  height: 40px;
  border-radius: 8px;
  border: none;
}

#cancel-submit-new-section {
  background-color: rgb(241, 241, 241);
  color: rgb(54, 54, 54);
  /* border: 1px solid rgb(205, 205, 205); */
}
#submit-new-section {
  /* position: absolute;
  bottom: 0;
  right: 0; */
  position: relative;

  background-color: var(--main-purple-no-transparancy);
  /* background-color: blue; */
  color: white;
}

.practice-form-container textarea {
  flex-shrink: 0;
  min-height: 55px;
}
