.liked-background {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  width: 30px;
  aspect-ratio: 1;
  /* background-color: #00000088; */
  background-color: transparent;
}
