html,
body,
#root {
  height: 100dvh;
}

:root {
  /* --fontColor: black;
  --backgroundColor: #ffffff;
  --videoButtonColor: #395dff;
  --videoButtonFontColor: white;
  --badgeFontColor: white;
  --lastChildNavBackground: #d3dbff;
  --videoAreaBackground: linear-gradient(
    to right,
    rgb(238, 238, 238) 20%,
    white 40%,
    white 90%,
    rgb(255, 255, 255) 100%
  );
  --navButtonColor: var(--videoButtonColor);
  --navButtonDisabledColor: #e3e8ff; */
  --trail-radius: 20px;
  --trail-length: 60px;
}

.outermost-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100dvh;
  width: 100vw;
}
.holder-of-nav-and-video-container {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.holder-of-nav-and-video {
  background-color: var(--background-light-gray);
  /* display: flex; */

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* flex-grow: 1; */
  /* background: linear-gradient(15deg, #b49f74, #86107f); */
  /* background: var(--videoAreaBackground); */
  /* background: rgb(22, 22, 22); */
  /* flex-flow: row; */
  /* flex-grow: 1; */
  /* height: 100vh; */
  /* height: 100%; */
}

.test-div {
  background: red;
  /* width: 100px; */
  width: 100px;
  /* height: 75vh; */
  flex-grow: 1;
}

.hold-visual-rows {
  /* display: block;
  flex-grow: 1; */
  display: flex;
  flex-direction: column;
  /* max-height: 90vh; */
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  /* display: grid;
  grid-auto-flow: row;
  overscroll-behavior-y: contain;
  justify-content: start; */
  /* display: flex; */
  /* flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1; */
}

#add-video-mobile {
  width: 50px;
  height: 50px;
  line-height: -10px;
  border: none;
  border-radius: 40px;
  background: var(--videoButtonColor);
  /* box-shadow: 0 8px 16px 0 rgba(253, 253, 253, 1), */
  /* 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  color: white;
  font-size: 40px;
}

.add-video-button-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 70px;
  width: 70px;
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% 0 0 0;
}

.AAAsection-bar-5::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 3px solid red; */

  background-color: green;
  z-index: 999;
}

.double-click-indicator,
.swipe-indicator,
.swipe-down-indicator {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 20px;
  height: 20px; */
  z-index: 999;
}

.double-click-indicator::before {
  pointer-events: none;
  top: -60px;
  left: -60px;
  /* transform: translate(-50%, -50%); */
  width: 120px;
  height: 120px;
  border-radius: 50%;

  content: '';
  position: absolute;
  display: block;
  /* width: 300%;
  height: 300%; */
  box-sizing: border-box;
  /* margin-left: -100%;
  margin-top: -100%; */
  /* border-radius: 45px; */
  background-color: #01a4e9;

  z-index: 999;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.double-click-indicator::after {
  pointer-events: none;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  content: '';
  position: absolute;
  /* left: 0;
  top: 0; */
  /* transform: translate(-50px, -50px); */
  display: block;
  /* width: 100%;
  height: 100%; */
  /* background-color: white; */
  background-color: #01a4e9;
  /* border: 3px solid #01a4e9; */
  opacity: 0.6;
  /* opacity: 0.5; */
  /* border-radius: 15px; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.swipe-indicator::before {
  pointer-events: none;
  top: calc(-1 * var(--trail-radius));
  left: -40px;
  /* transform: translate(-50%, -50%); */
  width: calc(var(--trail-radius) * 2);
  height: calc(var(--trail-radius) * 2);
  border-radius: var(--trail-radius);

  content: '';
  position: absolute;
  display: block;
  /* width: 300%;
  height: 300%; */
  box-sizing: border-box;
  /* margin-left: -100%;
  margin-top: -100%; */
  /* border-radius: 45px; */
  background-color: #01a4e9;
  /* background: linear-gradient(
    to right,
    #01a4e955 0%,
    #01a4e9 80%,
    #01a4e9 100%
  ); */

  z-index: 999;
  animation: swipe-trail 1.25s ease-out infinite;
}

.swipe-indicator::after {
  pointer-events: none;
  top: -20px;
  left: -40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  content: '';
  position: absolute;
  /* left: 0;
  top: 0; */
  /* transform: translate(-50px, -50px); */
  display: block;
  /* width: 100%;
  height: 100%; */
  /* background-color: white; */
  background-color: #01a4e9;
  /* border: 3px solid #01a4e9; */
  /* opacity: 0.6; */
  /* opacity: 0.5; */
  /* border-radius: 15px; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: swipe-dot 1.25s ease-out infinite;
}

@keyframes swipe-trail {
  0% {
    width: calc(var(--trail-radius) * 2);
    opacity: 1;
    transform: scale(1);
  }
  80% {
    /* opacity: 0.7; */
  }
  100% {
    width: calc(var(--trail-radius) * 2 + var(--trail-length));
    transform: scale(1);
    opacity: 0.3;
  }
}

@keyframes swipe-dot {
  0% {
    /* transform: scale(0.8); */
  }
  100% {
    transform: translateX(var(--trail-length));
    /* transform: scale(0.8); */
  }
}
.swipe-down-indicator::before {
  pointer-events: none;
  top: calc(-1 * var(--trail-radius));
  left: calc(-1 * var(--trail-radius));
  /* transform: translate(-50%, -50%); */
  width: calc(var(--trail-radius) * 2);
  height: calc(var(--trail-radius) * 2);
  border-radius: var(--trail-radius);

  content: '';
  position: absolute;
  display: block;
  /* width: 300%;
  height: 300%; */
  box-sizing: border-box;
  /* margin-left: -100%;
  margin-top: -100%; */
  /* border-radius: 45px; */
  background-color: #01a4e9;
  /* background: linear-gradient(
    to right,
    #01a4e955 0%,
    #01a4e9 80%,
    #01a4e9 100%
  ); */

  z-index: 999;
  animation: swipe-down-trail 1.25s ease-out infinite;
}

.swipe-down-indicator::after {
  pointer-events: none;
  top: -20px;
  left: calc(-1 * var(--trail-radius));
  width: 40px;
  height: 40px;
  border-radius: 50%;

  content: '';
  position: absolute;
  /* left: 0;
  top: 0; */
  /* transform: translate(-50px, -50px); */
  display: block;
  /* width: 100%;
  height: 100%; */
  /* background-color: white; */
  background-color: #01a4e9;
  /* border: 3px solid #01a4e9; */
  /* opacity: 0.6; */
  /* opacity: 0.5; */
  /* border-radius: 15px; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 999;
  animation: swipe-down-dot 1.25s ease-out infinite;
}

@keyframes swipe-down-trail {
  0% {
    height: calc(var(--trail-radius) * 2);
    opacity: 1;
    transform: scale(1);
  }
  80% {
    /* opacity: 0.7; */
  }
  100% {
    height: calc(var(--trail-radius) * 2 + var(--trail-length));
    /* transform: scale(1.2); */
    opacity: 0.3;
  }
}

@keyframes swipe-down-dot {
  0% {
    /* transform: scale(0.8); */
  }
  100% {
    transform: translateY(var(--trail-length));
    /* transform: scale(0.8); */
  }
}

.video-portal {
  z-index: 2000;
  position: fixed;
}

.three-panel-container {
  /* width: 300%; */
  position: relative;

  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .three-panel-container {
    transition: left 0.2s;
  }
}
