.label-holder-oval {
  /* border: 1px solid rgb(223, 170, 255); */
  /* background-color: rgb(255, 232, 232); */
  display: inline-block;
  border-radius: 15px;
  /* padding: 0 3px; */
  /* margin: 3px 0; */
}

.flow-chart-container {
  /* background: linear-gradient(to bottom, rgb(61, 129, 255), purple); */
  /* min-height: 100px; */
}
