:root {
  --text-gray-2: rgb(154, 154, 154);
}

.card-sx {
  /* padding: 12px; */

  width: 320px;
  min-width: 290px;
  max-width: 360px;
  flex-grow: 1;
  margin: 10px;
  padding: 0;
  /* gap: 10px; */
  /* display: flex;
  flex-direction: column; */
  /* justify-content: space-between; */
  /* box-shadow: 2px 1px 4px 0 rgb(207, 207, 207); */

  position: relative;
  border-radius: 8px;
}

.card-sx-specific {
  /* background-color: white; */
  color: black;
}

.main-content-card {
  min-height: 155px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  /* border-bottom: 1px solid gray; */
  border-radius: 8px;
  overflow: hidden;
}

.circle-overlap {
  background-color: white;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  transform: translate(50%, -50%);
  top: 50%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.goal-card {
  background-color: var(--main-purple-no-transparancy);
  color: white;
}

.practice-container-outer {
  height: 100%;
  /* transition: opacity 0.8s; */
  /* transition: transform 0.8s; */

  /* transform-style: preserve-3d; */
  /* height: 70vh; */
  /* visibility:visible */
}

.card-and-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  justify-content: center;

  /* justify-content: space-around; */
  /* flex-grow: 1; */
}

.flexible-upvote-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 65px;
}

.hidden.practice-container-outer {
  animation: card-out 0.3s forwards;
  /* visibility: hidden; */
  /* transform: rotateY(90deg); */
  /* transform: translateY(-200px);
  opacity: 0.3; */
}

@keyframes card-out {
  from {
    transform: translateY(0);
    opacity: 1;
    /* margin-left: 100%; */
    /* width: 300%; */
  }

  to {
    transform: translateY(-200px);
    opacity: 0;
    /* margin-left: 0%;
    width: 100%; */
  }
}

.swipe-in.practice-container-outer {
  animation: next-card-in 500ms forwards;
  /* visibility: hidden; */
  /* transform: rotateY(90deg); */
  /* transform: translateY(-200px);
  opacity: 0.3; */
}

@keyframes next-card-in {
  from {
    transform: translateX(100vw);
    opacity: 1;
    /* margin-left: 0%;
    width: 100%; */
  }
  to {
    transform: translateX(0);
    opacity: 1;
    /* margin-left: 100%; */
    /* width: 300%; */
  }
}

.practice-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  background-size: 100% 200%; */
}

.practice-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.practice-heading {
  font-size: 24px;
  height: 35px;
}

.practice-status-container {
  /* font-weight: bold; */
  font-size: 20px;
  height: 25px;
}

.practice-status-container [status='complete'] {
  color: green;
}

.practice-status-container [status='remaining'] {
  color: red;
}

.practice-notes {
  color: var(--text-gray-2);
}

.label-container-practice-card {
  color: var(--text-light-gray);
  /* color: var(--main-purple-no-transparancy); */
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label-in-practice-cards {
  font-size: 12px;
  /* display: inline-block; */
  min-width: 27px;

  /* border: 1px solid var(--main-purple-no-transparancy); */
  background-color: rgb(237, 237, 237);
  /* background-color: rgb(241, 232, 255); */
  border: none;

  /* border: 1px solid var(--text-gray-2); */
  border-radius: 15px;
  padding: 2px 7px;
  margin: 4px 0;
}
.label-in-practice-cards-col {
  font-size: 12px;
  /* display: inline-block; */
  min-width: 27px;

  /* border: 1px solid var(--main-purple-no-transparancy); */
  background-color: rgba(237, 237, 237, 0.82);
  /* background-color: rgb(241, 232, 255); */
  border: none;

  /* border: 1px solid var(--text-gray-2); */
  border-radius: 15px;
  padding: 2px 7px;
  margin: 4px 0;
}

.logo-and-info {
  /* z-index: 200; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: var(--text-light-gray);
  /* position: relative;
  right: 34px; */
  /* border: 4px solid white; */
}
.class-description {
  /* font-size: 12px; */
}

.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.play-text-and-icon {
  color: var(--accent);
  background-color: rgb(255, 235, 250);
  /* color: var(--main-purple-no-transparancy); */
  /* color: white; */
  border-radius: 15px;
  font-size: 12px;
  gap: 2px;
  padding: 0 7px 0 2px;
}

.arrow-between-labels {
  display: inline-block;
  margin: 4px;
}

.filter-div-practice-card {
  /* -webkit-filter: grayscale(1); */
}

.filter-div-practice-cardl ::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  mix-blend-mode: saturation;
  /* -webkit-filter: invert(100%);
  filter: invert(100%); */
}

.filter-div-prsactice-card ::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  background-color: blue;
  /* mix-blend-mode: multiply; */
}

.play-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.completed-days-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* flex-direction: column; */
  justify-content: space-between;
  color: white;
}

.single-day-block {
  font-size: 2px;
  width: 20px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;

  /* margin: 2px;
  border-radius: 5px; */
}

:root {
  --day-gray: rgb(238, 238, 238);
  --day-green: rgb(7, 206, 0);
  --day-blue: rgb(78, 160, 255);
}

.single-day-block.default {
  background-color: var(--day-gray);
  color: transparent;
}

.single-day-block.complete {
  background-color: var(--day-green);
  /* color: transparent; */
}

.single-day-block.rest {
  /* background-color: rgb(206, 237, 206); */
  background: repeating-linear-gradient(
    45deg,
    var(--day-green),
    var(--day-green) 1px,
    rgb(238, 238, 238) 1px,
    rgb(238, 238, 238) 3px
  );
  color: transparent;
}
.single-day-block.current-day {
  background-color: var(--day-blue);
  /* color: transparent; */
}

.move-button-container {
  /* position: absolute; */
  /* right: 2px;
  bottom: 2px; */
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.drag-button {
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */

  /* background-color: rgb(215, 214, 214); */
}

.card-button-container {
  /* background-color: rgb(138, 150, 255); */
  /* flex-grow: 1; */
  height: 30px;
  min-height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  gap: 10px;
}

.card-button-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--light-blue); */
  width: 30px;
  border-radius: 50%;
  aspect-ratio: 1;
  /* padding-top: ; */
  color: white;
}

.card-button-bottom-demo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--light-blue); */
  width: 30px;
  border-radius: 50%;
  aspect-ratio: 1;
  /* padding-top: ; */
  color: white;
  /* background-color: var(--demo-color); */
}

.stop-watch-time {
  color: var(--text-light-gray);
}

.stopwatch-more-options {
  /* background-color: rgb(206, 225, 255); */
  /* min-height: 50px; */
  /* box-sizing: content-box; */
  height: 0;
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: flex-start; */
  gap: 45px;
  border-top: 1px solid rgb(199, 199, 199);
  z-index: -1;
  /* flex-shrink: 0; */
}

.laptime-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* padding-i */
}

.save-time-button {
  background-color: rgb(168, 255, 168);
  color: green;
  border-radius: 15px;
  padding: 3px 10px;
}
