body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--white);
  --accent: var(--pink);
  --accent-darker: #ff0267;
}

.box {
  /* width: 15px; */
  height: 150px;
  /* border-radius: 50%; */
  /* background: var(--accent); */
  margin: 0;
}

ul {
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 0;
  /* padding: 5px 0; */
  text-align: center;
}

ul,
li {
  list-style: none;
  /* padding: 0; */
  padding-left: 0;
  padding-right: 0;
}

.noLabel {
  /* height: 35px; */
  border-radius: 20px;
  top: 5px;
  bottom: 5px;
}
/* 
button {
  -webkit-appearance: button;
  background: gray;
  color: var(--background);
  border: none;
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  width: 150px;
} */

input {
  accent-color: var(--accent);
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 50px;
  align-items: center;
}

/* label {
  display: flex;
  align-items: center;
  margin: 20px 0;
} */

/* input[type='number'] {
  border: 0;
  border-bottom: 1px dotted var(--accent);

  margin-left: 10px;

  width: 50px;
}

input[type='number']:focus {
  outline: none;

}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
} */

.example {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.primary div.bar-shape {
  /* background: var(--accent); */
}
.primary > * .count-box {
  /* background: var(--accent); */
}

.tertiary div.bar-shape {
  /* background: #444444bb; */
}

.tertiary > * .count-box {
  /* background: #444444bb; */
}

.count-box {
  /* margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px; */
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.font-box {
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: none; */
}
