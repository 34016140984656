.App {
  text-align: center;
}
.video_container {
  /* position: absolute; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  /* width: 130%; */
  /* min-width: 40vw; */
  width: 100%;
  /* z-index: -1; */
  /* flex-grow: 1; */
}

.blurry-loading-image {
  position: absolute;
  top: 10vh;
  bottom: 10vh;
  width: 100%;
  /* height: 400px; */
  background-color: var(--background-color_BLUR);
  background-image: var(--background-gradient_BLUR);
}

.player__wrapper {
  /* position: absolute; */
  /* top: 0; */
  height: 100%;
  background-color: var(--background-color);
  background-image: var(--background-gradient);

  /* width: 10%; */
  /* height: 80%; */
  /* overflow: hidden; */
}

.player {
  /* border: 2px solid #7b2cbf;
  object-fit: fill;
  padding: 0;
  margin: 0; */

  /* height: fit-content; */
  /* padding: 0; */
  margin: 0;
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.player__wrapper_preview {
  position: absolute;
  /* top: 0;
  left: 0; */
  top: 0px;

  /* left: 50%;
  transform: translateX(-50%); */
  /* background-color: black; */
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  /* z-index: 50; */
  /* background-color: #7b2cbf; */
}

.black-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: black;
  /* animation: fadeOutPreview 1.05s ease-in-out; */
  z-index: 2;
}

.player-preview video {
  object-fit: cover;
  /* display: inline-block; */
  object-position: 50% 30%;
  width: 100%;
  height: 100%;
}
.player-preview {
  /* border: 2px solid #7b2cbf;
  padding: 0;
  margin: 0; */
  /* width: 100%; */
  height: 100%;
  width: 100%;
  /* display: inline-block; */

  position: relative;
  padding: 0;
  /* object-fit: cover;
  object-position: 50% 30%; */
  /* top: -50%; */
  animation: fadeOutPreview 0.5s ease-in-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
  /* height: fit-content; */
  /* padding: 0; */
  margin: 0;
  /* position: absolute; */
  /* height: 100px; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

h2 {
  color: #7b2cbf;
}

.slowmo-button-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 24px;
  background-color: rgba(0, 0, 0, 0.667);
  z-index: 6;
}

.slowmo-button {
  /* margin: 0.25rem; */
  -webkit-filter: grayscale(1) invert(1);
  filter: invert(1);
}

.colorize-button {
  /* color: #7b2cbf;
  background: #ec008c; /* The color you want the icon to change to */
  position: relative;
}
.colorize-button::after {
  top: 0;
  right: 0;
  position: absolute; /* Positions psuedo element relative to .icon */
  /* color: #7b2cbf; */
  width: 100%; /* Same dimensions as .icon */
  height: 100%;
  content: ''; /* Allows psuedo element to show */
  background: #ec008c; /* The color you want the icon to change to */
  mix-blend-mode: multiply; /* Only apply color on top of white, use screen if icon is black */
}

.scanning-element {
  height: 3px;
  width: 100%;
  background: rgb(240, 178, 32);
  position: absolute;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes custom-fadein {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.black-screen {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: black;
  height: 100%;
  width: 100%;
  z-index: 9999;
  animation-name: custom-fadein;
  animation-duration: 1s;
  pointer-events: none;
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

@keyframes fadeOutPreview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.outermost-container-for-real {
  position: fixed;
  width: 100%;
  /* max-width: 700px; */
  height: 100%;
  /* bottom: 0;
  top: 0;
  right: 0;
  left: 0; */
  z-index: 600;
  margin: auto;
}

@media (min-width: 768px) {
  .outermost-container-for-real {
    width: 80%;
    max-width: 750px;
    height: 90%;
    border-radius: 15px;
    overflow: hidden;
  }
}
