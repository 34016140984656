.goal-sum-outer-container {
  height: 45px;
  /* min-width: 110px; */
  border-radius: 25px;
  /* border: 2px solid white; */
  /* box-sizing: ; */
  /* background-color: rgb(243, 228, 255); */
  /* background-size: 300px 300px;
  background-position: 270px 50%;
  background-image: radial-gradient(
    at 50% 50%,

    rgb(200, 186, 228) 20px,
    rgb(243, 228, 255) 110px,
    rgb(243, 228, 255) 200px
  ); */
  /* background-color: rgb(233, 214, 249); */
  border-color: var(--main-purple-no-transparancy);
  background-color: var(--main-purple-light);
  color: var(--main-purple-no-transparancy);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.days-remaining-goals {
  font-size: 16px;
  padding-left: 12px;
}
