.stop-watch {
  height: 100%;
  width: 100%;
  max-width: 600px;
  /* background-color: #29292988; */
  /* background-color: #6f42c1; */
  background-color: var(--main-purple-no-transparancy);
  /* background-image: var(--background-gradient); */
  background-size: 100vw 100vh;
  position: relative;
  margin-inline: auto;
  /* animation: slide-in-from-top-stopwatch 300ms ease-out forwards; */
}

@keyframes slide-in-from-top-stopwatch {
  from {
    /* opacity: 0; */
    /* scale: 0; */
    transform: translateY(-110%);
  }
  to {
    /* opacity: 1; */
    /* scale: 1; */
    transform: translateY(0);
  }
}
