/* outermost row */
.visual-selection-container {
  color: black;
  margin: 0px -10px 0px -10px;
  border-bottom: 1px solid rgb(223, 221, 221);
}

.title {
  /* margin-left: 15px;
  margin-bottom: 10px; */
  /* margin-top: 5px; */
  margin: 0;
  font-size: 16px;
  color: var(--text-dark-gray);
  font-weight: bold;
}

.title.favorite {
  /* color: #ca2577; */
}

.title-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.see-all {
  font-size: 16px;
  cursor: pointer;
  color: rgb(97, 97, 253);
  /* margin-right: 10px; */
  /* text-decoration: underline; */
  /* font-weight: bold; */
}

.videos-expanded-vertically {
  width: 150px;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.video-box-container {
  color: white;
  /* border-bottom: 1px solid rgb(223, 221, 221); */
  right: 0;
  left: 0;
}

.video-box-container > a {
  scroll-snap-align: center;
}

.contain-the-scrollable {
  position: relative;
  width: 100%;
  /* height: fit-content; */
  flex-grow: 1;
}

.practice-contain-all {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(226, 226, 226);
  background-color: var(--background-light-gray);
  z-index: 1;
  /* margin-left: 10px; */
  /* height: 290px; */
  /* justify-content: ; */
}

.practice-contain-all.favorite {
  /* z-index: 0; */
  color: #ca2577;
  /* background: linear-gradient(
    to top,
    #ca2577 0%,
    transparent 30%,
    transparent 70%,
    #ca2577 100%
  ); */
}

.practice-contain-all h1 {
  color: var(--text-dark-gray);
  /* margin: 25px; */
}
