.font-box {
  opacity: 1;
  transition: all 0.3s;
  line-height: 15px;
  transform: scale(1);
  /* text-shadow: black 0px 0px 2px; */
}

@keyframes animate-color-to-selected {
  0% {
    color: red;
  }
  50% {
    color: yellow;
  }
  100% {
    color: green;
  }
}
@keyframes animate-color-from-selected {
  0% {
    color: red;
  }
  50% {
    color: yellow;
  }
  100% {
    color: green;
  }
}

/* .font-box::after() */

.hidden-w-shadow {
  /* background:#111; */
  /* color: #fff; */
  /* text-shadow: 1px 1px 10px #fff, 1px 1px 10px black; */

  text-shadow: 0 0 5px #fff, /* 0 0 10px #fff,  */ 0 0 15px #fff, 0 0 20px black,
    /* 0 0 35px black,  */ 0 0 40px black, /* 0 0 50px black,  */ 0 0 75px black;
  /* font-size:48px; */
  opacity: 0;
  transform: scale(1);
  /* background: radial-gradient(ellipse, #e66465, transparent); */
  /* background-color: red; */
  /* text-align:center; */
  transition: opacity 0.5s ease-in-out;
}

.note-icon {
  opacity: 1;
}

.pulsate {
  /* animation: scaleAnimation 2s infinite; */
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Start and end at 100% scale */
  }
  100% {
    transform: scale(1.1); /* Scale up to 120% at the midpoint */
  }
}

.hidden-w-shadow[isHighlighted='true'] {
  opacity: 1;
}

.bar-shape {
  position: absolute;
  right: 0;
  left: 0;
  /* bottom: 0; */
  /* width: 100%; */
  /* background-color: var(--pink); */
  /* border: 1px solid white; */
}
.count1234 {
  height: 35px;
  border-radius: 13px 13px 0 0;
  bottom: 0;
  /* border: 1px solid white;
  border-bottom: none; */
  /* border-bottom: 1px solid #ffffff33; */
}
.count5678 {
  height: 35px;
  border-radius: 0 0 13px 13px;
  top: 0;
  /* border: 1px solid white;
  border-top: none; */
}

li.section-bar {
  width: 140px;
  text-align: center;
  position: relative;
  /* padding-top: ; */
  height: 40px;
  /* width: 100%; */
  /* flex: 0 0 40px; */
  /* border-radius: 2px; */
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */

  /* padding: 10px 0; */
}

.selectionBar {
  height: 40px;
  border-radius: 0;
  /* top: 0; */
}

.selectionBar.selectionBarFirst {
  height: 35px;
  /* height: 40px; */
  border-radius: 13px 13px 0 0;
  /* top: 0; */
}

.selectionBar.selectionBarFirst {
  height: 35px;
  /* height: 40px; */
  border-radius: 13px 13px 0 0;
  /* top: 0; */
}

.selectionBar.selectionBarLast {
  height: 35px;
  /* height: 40px; */
  border-radius: 0 0 13px 13px;
  /* top: 0; */
}

.selectionBar.selectionBarLast.count1234,
.selectionBar.selectionBarFirst.count5678 {
  height: 40px;
  /* height: 40px; */
  border-radius: 0;
  /* top: 0; */
}

.icon-and-text-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2px;
}

.extra-to-center-text-w-note-icon {
  /* width: 18px; */
  flex-basis: 18px;
  height: 10px;
  flex-shrink: 10;
}

.circle-for-first-and-last {
  position: absolute;
  height: 34px;
  width: 34px;
  background-color: none;
  /* background-color: #ffffff33; */
  border-radius: 50%;
  z-index: 66;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-to-selection {
  /* color: transparent; */
  /* display: hidden; */
  color: white;
  visibility: hidden;
}
.add-to-selection:hover {
  /* color: transparent; */
  /* display: hidden; */
  background-color: var(--bar-highlight-color);
  /* color: white; */
  /* visibility: hidden; */
}

.bar-shape:hover .add-to-selection {
  visibility: visible;
  /* display: block; */
}

.drag-symbol-holder {
  position: relative;
  right: -2px;
  top: -2px;
  width: 24px;
  height: 100%;
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

.fade-font-box {
  -webkit-mask-image: linear-gradient(to right, #00000055, transparent);
  mask-image: linear-gradient(
    to right,
    #000000 0%,
    #000000 60%,
    transparent 78%,
    transparent 100%
  ); /* For other browsers */
  -webkit-mask-size: 100% 100%;
  mask-size: 140px;

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  -webkit-mask-position: 0% 0%;
  mask-position: top center;
}

.drag-symbol-background {
  background-color: none;
  position: absolute;
  right: -1px;
  width: 26px;
  height: 26px;
  border-radius: 16px;
  z-index: 1;
  border: 1px solid white;
}

.drag-arrow {
  z-index: 88;
  bottom: 4px;
  color: white;
  position: relative;
  /* font-size: 10; */
}

.drag-element {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #d0fcb3; /* #6f42c1; */
  border: none;
  /* border: 1px solid white; */
  position: absolute;
  right: 63px;
  color: white;
}

.top-drag-element {
  top: -10px;
}

.bottom-drag-element {
  bottom: -10px;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
