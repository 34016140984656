.badge {
  padding: 0.125rem 0.25rem;
  /* height: 17px; */
  margin: 3px 3px 3px 0;
  line-height: normal;
  /* font-size: 0.75rem; */
  font-size: 11px;
  /* height: 1.5rem; */
  background: red;
  border-radius: 2px;
  display: inline-block;
  /* font-weight: bold; */
  /* align-items: center; */
  /* position: relative; */
  /* justify-content: flex-start; */
}
