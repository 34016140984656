:root {
  --bar-background-color: rgba(92, 37, 202, 0.6);
  --bar-highlight-color: rgba(92, 37, 202, 0.6);
  --main-purple-no-transparancy: #5c25ca;
  /* --main-purple-no-transparancy: rgb(92, 37, 202); */
  --demo-color: rgb(212, 212, 212);
  --light-blue: rgb(163, 172, 255);
  --main-purple-light: rgb(243, 228, 255);
  --main-purple-light-more-purple: rgb(203, 189, 222);
  --highlight-color-1: yellow;
  /* --highlight-color-1: #22aed1; */
  --secondary-color-to-purple: rgb(0, 95, 113);
  /* --bar-background-color: hsla(335, 81%, 50%, 0.7); */
  --highlight-button-color: rgb(251, 164, 255);
  --background-light-gray: rgb(246, 246, 246);
  --background-light-gray-transparent: rgba(246, 246, 246, 0.8);
  --background-light-gray-transparenter-less: rgba(246, 246, 246, 0.88);
  --text-dark-gray: rgb(80, 80, 80);
  --text-light-gray: rgb(132, 132, 132);
  --text-lighter-gray: rgb(164, 164, 164);
  --background-gradient_DEP: linear-gradient(
    45deg,
    var(--dominant-colors-1),
    var(--dominant-colors-2) /* rgb(32, 32, 32),
    rgb(32, 32, 32) */
  );
  --background-color: rgb(50, 50, 50);
  --background-color_BLUR: var(--dominant-colors-1);
  /* --background-color: hsla(335, 30%, 32%, 1); */
  --background-gradient_BLUR: radial-gradient(
      at 12% 25%,
      var(--dominant-colors-2),
      transparent 50%
    ),
    radial-gradient(at 32% 79%, var(--dominant-colors-3) 0px, transparent 50%),
    radial-gradient(at 87% 13%, var(--dominant-colors-4), 0px, transparent 50%);

  --background-color_DEP: black;
  --background-gradient_DEP: linear-gradient(
    20deg,
    rgb(32, 32, 32) 0%,
    rgb(174, 24, 174) 20%,
    rgb(92, 37, 202) 80%,
    rgb(32, 32, 32) 100%
  );
  --background-gradient_progress: linear-gradient(
    20deg,
    rgb(174, 24, 174),
    rgb(92, 37, 202)
  );

  --background-gradient_DEP: linear-gradient(
    to right,
    #b8cbb8 0%,
    #b8cbb8 0%,
    #b465da 0%,
    #cf6cc9 33%,
    #ee609c 66%,
    #ee609c 100%
  );
  --background-colorDEP: hsla(278, 100%, 59%, 1);
  --background-gradientDEP: radial-gradient(
      at 49% 90%,
      hsla(352, 77%, 64%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 29% 89%, hsla(15, 65%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 91% 32%, hsla(260, 100%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 50% 35%, hsla(273, 97%, 68%, 1) 0px, transparent 50%),
    radial-gradient(at 14% 40%, hsla(334, 61%, 65%, 1) 0px, transparent 50%),
    radial-gradient(at 46% 52%, hsla(222, 95%, 78%, 1) 0px, transparent 50%);

  --background-color_DEP: #ff99b6;
  --background-gradient_DEP: radial-gradient(
      at 77% 48%,
      hsla(337, 97%, 60%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 36% 11%, hsla(8, 83%, 63%, 1) 0px, transparent 50%),
    radial-gradient(at 95% 31%, hsla(290, 84%, 65%, 1) 0px, transparent 50%),
    radial-gradient(at 46% 75%, hsla(279, 85%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 5% 38%, hsla(308, 96%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 49% 5%, hsla(97, 67%, 64%, 1) 0px, transparent 50%),
    radial-gradient(at 48% 96%, hsla(69, 84%, 63%, 1) 0px, transparent 50%);
}
