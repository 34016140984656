.practice .rec.rec-carousel-wrapper {
  max-height: 560px;
  /* min-height: 280px; */
  height: 470px;
}

.practice .rec.rec-slider {
  /* top: 0; */
  height: 100%;
  bottom: 0;
}
.practice .rec.rec-carousel {
  /* top: 0; */
  height: 100%;
  bottom: 0;
  flex-grow: 1;
}

.practice .rec.rec-item-wrapper {
  /* top: 0; */
  height: 100%;
  /* bottom: 0; */
}
