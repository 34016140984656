.all-studios-container {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.all-studios-container img {
  height: 60px;
  aspect-ratio: 1;
  /* background-color: red; */
  border-radius: 50%;
}

.studio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
