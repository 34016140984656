.topic-page-container {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  max-width: 600px;
  height: 100%;
  overflow-y: scroll;
  margin-inline: auto;
}
