.slomo-expanded {
  animation: expand-in 300ms ease-out forwards;
}

@keyframes expand-in {
  from {
    opacity: 0;
    scale: 0;
    transform: translateX(-70%);
  }
  to {
    opacity: 1;
    scale: 1;
    transform: translateX(0);
  }
}
