.outer-container-content {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.share-question-container {
  /* padding-top: 10px; */
  position: absolute;
  bottom: 0;
  left: 0;
  height: 55px;
  /* border-top: 1px solid var(--text-lighter-gray); */
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  background-color: var(--background-light-gray);
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0 -2px 14px 0 rgb(191, 189, 189);
}

.video-share-count {
  color: var(--accent);
  font-weight: bold;
}

.share-question-container button {
  border: none;
  border-radius: 20px;
  padding: 0px 15px;
  background-color: var(--accent);
  color: white;
}

.clear-all-search-shares {
  /* width: 40px; */
  /* min-width: 40px; */
  flex-shrink: 0;
  aspect-ratio: 1;
  /* border: 3px solid rgb(188, 188, 188); */
  color: rgb(188, 188, 188);
  border-radius: 50%;
  /* background-color: var(--accent); */

  /* margin-right: 10px; */
  display: flex;

  justify-content: center;
  align-items: center;
}

.container-to-hide-scroll {
  overflow: hidden;
  height: 40px;
  margin: 0 -15px;
}

.recent-search-chip-container.one-row {
  height: 45px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.mini-thumb-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  flex-grow: 1;

  overflow-x: scroll;
  height: 100%;

  /* -webkit-mask-image: linear-gradient(to left, transparent 0%, black 30px);
  mask-image: linear-gradient(to left, transparent 0%, black 30px); */
}

.send-video-mini-thumb {
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: cover;
  border-radius: 5px;
  /* margin-top: 10px; */
  box-sizing: content-box;
}

.cancel-mini {
  position: absolute;
  width: 15px;
  aspect-ratio: 1;
  top: -5px;
  right: -5px;
  color: var(--accent);
  background-color: rgb(255, 177, 230);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.share-button {
  height: 40px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
}

.pre-search-heading {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  color: black;
}

.pre-search-container {
  /* padding-left: 10px; */
}
