.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  opacity: 1;
  background-color: #000000aa;
  animation: apppear 0.3s forwards;
}
.backdrop-radial {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
}

@keyframes grow-radial {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(6);
  }
}

@keyframes apppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}

.backdrop-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  /* background-color: rgba(0, 0, 0, 0.75); */
  /* -webkit-filter: blur(30px);
  -moz-filter: blur(30px);
  -ms-filter: blur(30px);
  filter: blur(30px);
  filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); */
  animation: blur-in 1s forwards; /* Important */
}

/* Let's define an animation: */
@keyframes blur-in {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(2px);
  }
}

.modal {
  position: fixed;
  /* top: 10vh;
  bottom: 10vh; */
  max-height: 700px;
  /* top: 10vh; */
  /* bottom: 10vh; */
  /* margin: auto; */
  overflow: hidden;
  left: 5%;
  right: 5%;
  background-color: white;
  /* padding: 1rem; */
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  animation: slide-down 300ms ease-out forwards;
}

.modal-fixed-height {
  top: 10vh;
  height: 80vh;
  bottom: 10vh;
}

.modal-flexible-height {
  top: 10vh;
  min-height: 35vh;
  max-height: 80vh;
  /* height: fit-content; */
  display: flex;
  flex-direction: column;

  /* bottom: 10vh; */
}
/*
.modal-keyboard-height {
  top: 5vh;
  /* height: 40vh;
  bottom: 320px;
} */

.exit-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

.content {
  flex-grow: 1;
  /* height: 100%; */
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

.content-flexible {
  height: 100%;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }

  .backdrop-radial {
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.9) 0%,
      transparent 60%,
      transparent 100%
    );
    animation: grow-radial 2s forwards; /* Important */
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-input-modal {
  position: fixed;
  top: 10vh;
  width: 300px;
  max-width: 90%;

  max-height: 700px;
  /* top: 10vh; */
  /* bottom: 10vh; */
  margin-inline: auto;
  overflow: hidden;
  left: 50%;
  /* right: 5%; */
  background-color: white;
  /* padding: 1rem; */
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 300;
  animation: slide-down-centered 300ms ease-out forwards;
  /* transform: translateX(-50%); */
}

@keyframes slide-down-centered {
  from {
    opacity: 0;
    transform: translate(-50%, -3rem);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
