.more-bar-options-container {
  /* background-color: var(--background-color);
  background-image: var(--background-gradient);
  background-size: 100vw 100vh;
  background-position: bottom; */

  /* background-color: #292929; */
  color: white;
  /* height: 50px; */
  width: 100%;
  /* position: absolute; */

  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 500;

  pointer-events: auto;

  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  /* padding: 5px; */
  /* gap: 20px; */
}

.main-options {
  gap: '20px';
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--background-color);
  background-image: var(--background-gradient);
  background-size: 100vw 100vh;
  background-position: bottom;
}

.more-bar-options-container > div {
  /* margin: 10px; */
}

.more-bar-options-container img {
  /* margin: 10px; */
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
