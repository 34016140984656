.topic-container {
  width: 100%;
  /* overflow-y: scroll; */
  /* max-width: 340px; */
  /* background-color: var(--demo-color); */
  /* background-color: var(--background-light-gray); */
  /* background-color: purple;
    color: white; */
  color: rgb(80, 80, 80);

  /* font-weight: bold; */
  /* color: var(--main-purple-no-transparancy); */
  opacity: 1;
  transition: opacity 0.3s;

  /* background-color: white; */
  /* border-radius: 5px;
  margin: 5px;
  width: 80%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
  /* border-bottom: 1px solid rgb(204, 204, 204); */
}

.border-bottom-topic {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 5px;
  border-bottom: 2px solid lightgray;
  margin-inline: 10%;
  /* box-sizing: border-box; */
  /* z-index: 21; */
}

/* .topic-heading {
  background-color: var(--background-light-gray-transparenter-less);
  position: absolute;
} */
.topic-heading.open {
  /* background-color: rgba(255, 192, 203, 0.346); */
  /* box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.208); */
  /* z-index: 33; */
  /* position: relative; */
  /* background: none; */
  /* backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); */
}

@media (min-width: 600px) {
  .topic-heading.open {
    box-shadow: none;
  }
}

.topic-container.after-open {
  /* box-shadow: 0 -5px 6px 0 rgb(210, 210, 210); */

  opacity: 0;
  /* background-color: red; */
}
.topic-heading {
  /* box-shadow: 0 5px 6px 0 rgb(210, 210, 210); */
  background-color: var(--background-light-gray);
  position: absolute;
  z-index: 55;
  right: 0;
  left: 0;
  /* position: relative; */

  height: 65px;
  line-height: 65px;
  padding-inline: 10%;

  display: flex;
  flex-direction: row;
  color: inherit;

  /* color: var(--secondary-color-to-purple); */
  align-items: center;
  gap: 15px;
  /* background-color: white;
  margin: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
}

.topic-name {
  flex-grow: 1;
  font-size: 20px;

  /* font-weight: bold; */
}

.topic-emoticon {
  font-size: 30px;
}

.days-in-header {
  color: var(--main-purple-no-transparancy);
  font-size: 13px;
}

.days-in-header > span {
  padding: 0px;
}

.day-NOT-marked-bold {
  opacity: 0.2;
}

.day-marked-bold {
  font-weight: bold;
  /* opacity: 1; */
}

.topic-caret {
  transform: rotate(0);
  -webkit-transform: rotate(0deg);
  transition: all 200ms;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
}

.topic-caret.caret-rotated {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.topic-video-container {
  width: 100%;
  /* height: 100%; */
  overflow-y: scroll;
  /* position: absolute; */
  /* top: 0px; */
  /* border-bottom: 2px solid rgb(210, 210, 210); */
  /* background: linear-gradient(to bottom, white, rgb(210, 210, 210)); */
  /* background: var(--background-gradientDEP); */
  /* background-color: rgb(204, 204, 204); */
  /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap; */
  /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); */
  /* gap: 10px; Adjust as needed for spacing between items */
  /* width: 100%; Ensure the container fills its parent */
  /* gap: 5px; */
  padding: 0 5px;
  /* padding-top: 0; */
  /* background-color: var(--main-purple-light-more-purple); */
  /* opacity: 1; */
  /* transition: opacity 200ms; */
}

.topic-video-container.unlocked {
  /* opacity: 0.7; */
  /* background-color: rgba(255, 255, 255, 0.457); */
  /* animation: blink 1s infinite alternate; */
  /* background-color: rgb(255, 208, 174); */
}

.test-grid-container {
  /* background-color: blue; */
  background-color: var(--demo-color);
  /* color: green;  */
  overflow: hidden;
  /* border-radius: 5px; */
  position: relative;
}

.test-grid-container.text {
  color: rgb(77, 111, 47);
}

.collage-text {
  /* background-color: rgb(255, 251, 199); */
  background-color: rgb(219, 255, 188);
  color: rgb(77, 111, 47);
  font-size: 12px;
  padding: 5px;
  width: 100%;
  height: 100%;
  /* padding-right: 20px; */
  /* overflow: hidden; */
}
