.purple-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bar-highlight-color);
  background: linear-gradient(
    to bottom,
    var(--bar-highlight-color) 0%,
    var(--bar-highlight-color) 30%,
    var(--main-purple-no-transparancy) 90%,
    var(--main-purple-no-transparancy) 100%
  );
  border-radius: inherit;
  z-index: 3;
  /* pointer-events: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: white;
  font-size: 50px;

  /* animation: fadein-opacity 0.3s ease-in-out; */
}

.button-purple-overlay {
  font-size: 40px;
  width: 40px;
  height: 40px;
  color: rgb(220, 189, 255);
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.timer-overlay-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;

  font-size: 35px;
}

@keyframes fadein-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.play-video-over-purple {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 20px;
  color: rgb(220, 189, 255);
}
