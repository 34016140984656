* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  /* color: #252525; */
  /* background-color: #252525; */
}

/* button {
  font: inherit;
  cursor: pointer;
  background-color: #3c0080;
  border: 1px solid #3c0080;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
} */
/* 
button:hover,
button:active {
  background-color: #5b14ac;
  border-color: #5b14ac;
} */

.tour-double-click {
  width: 500px;
  height: 500px;
  background-color: purple;
}

.lll {
  width: 500px;
  height: 500px;
  background-color: purple;
}

/* .lll::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid red;

  background-color: green;
  z-index: 999;
} */
