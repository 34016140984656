.section-bar-container {
  position: relative;
  /* z-index: 2; */
  width: 150px;
  height: 37px;
  /* background-color: rgb(0, 51, 133); */
  color: white;
  text-align: center;
  /* vertical-align: 40px; */
  display: flex;
  vertical-align: middle;
  line-height: 30px;
  /* border: 1px solid black; */
  border-radius: 0px;
  margin: 0px;
  font-size: 1rem;
  /* display: flex; */
  justify-content: space-between;
  /* padding-left: 10px; */
  cursor: pointer;
}

.secondaryMove {
  background-color: darkgray;
}

.title {
  /* width: 200px; */
  /* flex-grow: 1; */
  color: white;

  /* position: relative; */
  /* left: 0px; */
  /* width: 60%; */

  display: inline-block;
  vertical-align: middle;
}

.selected {
  background-color: '#7b2cbf';
  /* color: black; */
  /* width: 500px; */
}
/* .profile {
  margin: 5rem auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  width: 40rem;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  background-color: #f4f0fa;
} */

.section-clickables {
  /* border: solid white; */
  color: white;
  font-size: 25px;
  padding: 0 6px;
  margin-right: 4px;
  vertical-align: middle;
}

.dividing-line {
  position: absolute;
  bottom: 0;
  /* padding: 0 60px; */
  /* padding-left: 60px; */
  margin: 0 auto;
  /* margin-right: -10px; */
  left: 0px;
  right: 0px;
  background: rgb(175, 139, 197);
  /* width: 40px; */
  border-radius: 10px;
  height: 4px;
  /* z-index: 7; */
}

.more-options-button {
  position: absolute;
  /* font-size: 50px; */
  width: 30px;
  height: 30px;
  bottom: 0px;
  right: 5px;
  font-size: 24px;
  /* z-index: 123; */
  /* padding: 0;
  margin: 0; */
  /* background: none; */
  background: #7b2cbf;
  /* background: green; */
  border-radius: 20px;
  /* border: none; */

  /* border: 1px solid #ffffff99; */
  border: none;
  color: white;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.radii-change-test {
  /* padding: -10px; */
  /* margin: 10px; */
  height: 37px;
  width: 150px;
}

div {
  --large-radius: 15px;
}

/* Different border options */
/* for 1234 counts */
/* normal */
.radii-change-test[count='1234'] {
  height: 42px;
  border-radius: var(--large-radius) var(--large-radius) 0 0;
}

.radii-change-test[count='5678'] {
  height: 37px;
  border-radius: 0 0 var(--large-radius) var(--large-radius);
}

/* for 1234 counts selected*/

.radii-change-test.only-element {
  /* height: 37px; */
  /* border-bottom-left-radius: 0; */
  /* border-bottom-right-radius: var(--large-radius); */
}

.radii-change-test.first-element {
  height: 42px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  /* border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white; */
}

.radii-change-test.middle-element {
  height: 42px;
  border-radius: 0 0 0 0;
  /* border-left: 1px solid white;
  border-right: 1px solid white; */
}

.radii-change-test.last-element {
  /* height: 37px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  /* border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white; */
}

.radii-change-test.last-element[isSelected='true'] {
  /* border-bottom-right-radius: var(--large-radius); */
}
