.control_Container {
  /* background-color: rgba(0, 0, 0, 0.6); */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.search-faded-top {
  background: linear-gradient(
    from top,
    rgba(0, 0, 0, 0) 0px,
    rgba(0, 0, 0, 1) 15px
  );
  /* -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(10%, rgba(0, 0, 0, 1))
  ); */
}

.faded-top {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(var(--percent-diff), rgba(0, 0, 0, 1)),
    color-stop(93%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  -webkit-mask-size: 100%
    calc(var(--sections-box) - var(--fade-top) + var(--fade-bottom));
  -webkit-mask-position: 0% var(--fade-top);
  /* -webkit-mask-position-x: 0%;
  -webkit-mask-position-y: -30px; */

  -webkit-mask-repeat: no-repeat;
  transition: -webkit-mask-position 0.3s, -webkit-mask-size 0.3s;
}

.section-list-outer-container {
  position: absolute;

  top: 5%;
  width: 150px;
  right: 0px;
  bottom: 8%;
}

.section-list {
  padding-top: 20px;
  padding-bottom: 20px;

  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 390;
  animation: slide-in 300ms ease-out forwards;

  /* fadeTop 2s linear forwards infinite; */
}

.slide-in-from-left {
  animation: slide-in-control-buttons 300ms ease-out forwards;
}

.slide-in-from-bottom {
  animation: slide-in-bottom 300ms ease-out forwards;
}

.left-contols-grouping {
  /* background-color: rgba(92, 37, 202, 0.6); */
  /* background-color: rgb(251, 164, 255); */
  border-radius: 0 20px 20px 0;
}

@keyframes slide-in-control-buttons {
  from {
    opacity: 0;
    /* scale: 0; */
    transform: translateX(-110%);
  }
  to {
    opacity: 1;
    /* scale: 1; */
    transform: translateX(0);
  }
}

@keyframes slide-in-bottom {
  from {
    opacity: 0;
    /* scale: 0; */
    transform: translateY(110%);
  }
  to {
    opacity: 1;
    /* scale: 1; */
    transform: translateY(0);
  }
}

/* .hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
} */

.invert-pic {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.stopwatch-container {
  height: 40px;
  width: 100%;
  position: absolute;
}

.top-absolute-container {
  position: absolute;
  width: 100%;
}

.top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
}

.mid__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.icon__btn {
  padding: 0 10px;
  color: #7b2cbf;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.slider__container {
  /* width: 100%; */
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 16px;
}

.played-duration-container {
  width: 100%;
  /* position: absolute; */
  /* top: 2px; */
  /* top: 0; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: space-between; */
  align-items: center;
  color: rgb(166, 166, 166);
}

.controls-bottom {
  width: calc(100% + 15px);
  margin-inline: -10px;
  position: absolute;
  bottom: 2px;
  /* top: 0; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 10px;
  /* justify-content: space-between; */
  align-items: center;
  /* color: rgb(166, 166, 166); */
  color: white;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.21));
}

.individual-time {
  width: 32px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.21);
}

.actual_mid__container {
}

.AB__container {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  /* z-index: 6; */
  transform: translateY(-30px);
}

.bottom__container {
  z-index: 401;
  /* position: relative; */
  /* display: grid;
  grid-template-columns: 1fr; */
}

.bottom__container div {
  grid-row-start: 1;
  grid-column-start: 1;
}

.control__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner__controls {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 100%;
}

/* span {
  color: #fff;
  font-size: 0.8rem;
  margin-left: 10px;
} */

.second__control {
  display: flex;
  align-items: center;
}

.captions {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 10px; */
  z-index: 450;
  width: 100%;
  height: 25%;
  margin: auto;
  pointer-events: none; /*Ensure the overlay does not block clicks*/
}

.single-caption {
  /* width: 50px; */
  color: white;
  display: block;
  background-color: #000000;
  /* background-color: var(--background-color); */
  padding: 5px 15px;
  margin: 10px;
  /* border: 1px white solid;
  border-radius: 5px; */
}

.out-of-bounds-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  background-color: #575757;
  color: white;

  position: absolute;
  /* top: -22px; */
  /* right: 50px; */
  right: 8px;

  z-index: 400;

  display: flex;
  justify-content: center;
  align-items: center;
}

.top.out-of-bounds-button {
  /* top: 16px; */
  top: -2px;
  /* transform: translateX(50%); */
}

.bottom.out-of-bounds-button {
  bottom: 20px;
  /* transform: translateX(50%); */
}
