.container {
  /* background: yellow; */
  position: relative;
  /* left: -16px;
  right: -16px; */
  width: 100%;

  /* width: -webkit-calc(100% + 32px);
  width: -moz-calc(100% + 32px);
  width: calc(100% + 32px); */
  height: 100%;
  /* width: 100% + 32px; */
  /* padding-left: -16px; */
  /* height: 40%; */
  /* margin-top: 2rem; */
  /* display: flex;
  justify-content: space-between;
  flex-direction: column; */
}

.url-form-element {
  /* background: gray; */
  /* margin: 1rem; */

  display: flex;
}

.error-text {
  color: red;
  margin: 0 auto;
}

.fetch-button {
  background-color: var(--videoButtonColor);
  color: var(--videoButtonFontColor);
  border: 1px solid var(--videoButtonColor);
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.button-container {
  display: inline-block;
}

.url-form-element input {
  /* width: 90%; */
  flex-grow: 1;
  display: inline-block;
  border: 1px solid gray;
  border-radius: 5px;
}

.center-input {
  white-space: nowrap;
  max-width: 500px;
  width: 80%;
  margin: auto;
  margin-bottom: 15px;
}
