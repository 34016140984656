.adapting-background {
  width: 100%;
  height: 100dvh;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.floating-box {
  margin: 0 auto;
  position: relative;
  max-width: 280px;
  width: 90%;
}

.login-div {
  width: fit-content;
  color: white;
  font-size: 35px;
  font-weight: 'bold';
  z-index: 4;
  position: relative;
}

.auth {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 22px;
  /* margin: 0 auto; */
  /* text-align: center; */
  background-color: white;
  position: relative;
  z-index: 2;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 10px; */
}

.form-elements {
  width: 100%;
}

.error {
  color: red;
  font-size: 12px;
  text-align: center;
}

/* .floating-box input {
  width: 100%;
} */

.greeting {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: rgb(58, 57, 59);
  /* color: black; */
  margin-bottom: 3px;
}

.instructions {
  text-align: center;
  color: rgb(116, 115, 115);
  margin-bottom: 25px;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #616161;
  /* text-transform: uppercase; */
  margin-bottom: 0.5rem;
}

.control input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  padding: 0.25rem;
  border: 1px solid #ccc;
}

.error-text {
  color: #b40e0e;
}

.login-button {
  border: none;
  /* border-radius: 50%; */
  color: white;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  margin: 30px 0 0;

  /* background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202)); */
  background: rgb(92, 37, 133);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.login-button.logging-in {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    background: rgb(92, 37, 133);
  }
  50% {
    background: rgb(166, 112, 207);
  }
}
