.badge-container {
  /* margin-top: 5px; */
  /* height: 25px; */
  /* margin-left: 23px; */
  line-height: 10px;
  /* height: 22px; */
  overflow: hidden;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 0 4px;
  flex-wrap: wrap;
}
