:root {
  --highlight-button-color: rgba(151, 185, 253, 0.5);
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 300px;
  max-width: 400px;
  flex-grow: 1;
}

.practice-action-buttons {
  position: relative;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  margin: 3px;
  border: 10px solid #ddd;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23); */
  z-index: 2;

  /* max-width: 150px; */
  /* flex-grow: 1; */
}

.button {
  /* box-sizing: border-box; */
}

/* .button {

} */

.practice-action-buttons:active::after,
.practice-action-buttons:active ~ .frequency-cirlce-behind::after {
  content: ''; /* Required to make a pseudo-element */
  position: absolute; /* Position it absolutely to the button */
  top: -10px;
  left: -10px;
  width: calc(100% + 20px); /* Cover the entire button */
  height: calc(100% + 20px); /* Cover the entire button */
  border-radius: 50%; /* Ensure the overlay is also circular */
  background-color: var(
    --highlight-button-color
  ); /* Overlay color with transparency */
  pointer-events: none; /*Ensure the overlay does not block clicks*/
}
.practice-action-buttons:active + .frequency-cirlce::after {
  content: ''; /* Required to make a pseudo-element */
  position: absolute; /* Position it absolutely to the button */
  top: 0px;
  left: 0px;
  width: 100%; /* Cover the entire button */
  height: 100%; /* Cover the entire button */
  border-radius: 50%; /* Ensure the overlay is also circular */
  background-color: var(
    --highlight-button-color
  ); /* Overlay color with transparency */
  pointer-events: none; /* Ensure the overlay does not block clicks */
}
.practice-action-buttons:active + .frequency-cirlce-behllind {
  content: ''; /* Required to make a pseudo-element */
  position: absolute; /* Position it absolutely to the button */
  top: 0px;
  left: 0px;
  width: 100%; /* Cover the entire button */
  height: 100%; /* Cover the entire button */
  border-radius: 50%; /* Ensure the overlay is also circular */
  background-color: var(
    --highlight-button-color
  ); /* Overlay color with transparency */
  pointer-events: none; /* Ensure the overlay does not block clicks */
}

.pagination-button:active::after {
  content: ''; /* Required to make a pseudo-element */
  position: absolute; /* Position it absolutely to the button */
  top: -6px;
  left: -6px;
  width: calc(100% + 12px); /* Cover the entire button */
  height: calc(100% + 12px); /* Cover the entire button */
  border-radius: 50%; /* Ensure the overlay is also circular */
  background-color: var(
    --highlight-button-color
  ); /* Overlay color with transparency */
  pointer-events: none; /* Ensure the overlay does not block clicks */
}

.pagination-button {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* margin: 5px; */
  border: 6px solid #ddd;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  background-color: white;
  font-size: 30px;
  /* transition: transform 0.3s ease; */
}

.practice-butt-text {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.practice-butt-sub-text {
  font-size: 12px;
  color: #ffffffaa;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 6px;
}

.complete {
  background-color: white;
  color: green;
  /* border: 1px solid blue; */
}
.start {
  background-color: green;
  color: white;
}
.snooze {
  background-color: white;
  color: red;
  /* border: 3px solid red; */
}

.frequency-cirlce {
  color: green;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  bottom: 24px;
  right: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid white; */
  background-color: white;
  transform: translate(50%, 50%);
  z-index: 6;
}

.frequency-cirlce-behind {
  background-color: inherit;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid #ddd;
  z-index: 1;
  transform: translate(50%, 50%);
}

.practice-out-of-progress {
  font-size: 25px;
  color: var(--text-light-gray);
}
