.progress-outer {
  width: 100%;
  height: 100%;
  /* background: var(--background-gradient_progress); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 5% 35px;
  /* padding-right: 20px; */
  /* background-color: var(--main-purple-light); */
  background-color: rgb(221, 221, 255);

  /* color: var(--main-purple-no-transparancy); */
  color: rgb(36, 36, 174);
  /* border: 1px solid green; */
}

.week-contents {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.day-n-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-day-digit {
  opacity: 60%;
}
