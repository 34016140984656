.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px 10px 10px 10px;
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

.sub-container {
  height: 89%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-holder {
  /* font-size: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  /* position: absolute; */
  /* top: 0; */
  /* right: 50%; */

  /* transform: translateX(50%); */
}

.text-holder {
  font-size: 12px;
  height: 12px;
  /* position: absolute; */
  /* bottom: 0; */
  /* right: 50%; */
  /* transform: translateX(50%); */
}
