.outer-container {
  padding: 35px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.outer-container h2 {
  /* color: var(--text-dark-gray); */
  color: black;
}

.outer-container p {
  color: var(--text-light-gray);
  text-align: center;
}
