.timer {
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  display: flex;

  /* align-items: center; */
  /* display: flex;
  height: 12%; */
  /* box-sizing: border-box; */
  justify-content: center;
  align-items: center;
}

.digits {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* font-size: 3rem; */
  color: #f5f5f5;
}

.mili-sec {
  color: #e42a2a;
}
