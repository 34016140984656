/* body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}
section {
  margin: 0 auto; 
  max-width: 660px;
  padding: 0 20px;
}
h1 {
  font-weight: normal;
  position: relative;
  background: #F4F9FA;
  width: 50%;
  color: #F8463F;
  text-align: center;
  padding: 10px 20px;
  margin: 20px auto 40px;
  text-transform: uppercase;
  border-radius: 2px;
}
h1:before, 
h1:after {
  content: "";
  position: absolute;
  top: -6px;
  border: 18px solid #DCF4F4;
}
h1:before {
  left: -36px;
  border-right-width: 18px;
  border-left-color: transparent;
}
h1:after {
  right: -36px;
  border-left-width: 18px;
  border-right-color: transparent;
}
h1 span:before, 
h1 span:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #A7CECC transparent transparent transparent;
  top: -6px;
  transform: rotate(180deg);
}
h1 span:before {
  left: 0;
  border-width: 6px 0 0 6px;
}
h1 span:after {
  right: 0;
  border-width: 6px 6px 0 0;
} */
.ribbon {
  height: 188px;
  position: relative;
  margin-bottom: 30px;
  background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
  background-size: cover;
  text-transform: uppercase;
  color: white;
}
.ribbon1 {
  position: absolute;
  top: -6.1px;
  right: 10px;
}
.ribbon1:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 53px solid transparent;
  border-right: 53px solid transparent;
  border-top: 10px solid #f8463f;
}
.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #f8463f;
  font-size: 14px;
  line-height: 1;
  padding: 12px 8px 10px;
  border-top-right-radius: 8px;
  width: 90px;
}
.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: '';
}
.ribbon1 span:before {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #f8463f;
}
.ribbon1 span:after {
  height: 6px;
  width: 8px;
  left: -8px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #c02031;
}
.ribbon2 {
  width: 60px;
  padding: 10px 0;
  position: absolute;
  top: -6px;
  left: 25px;
  text-align: center;
  border-top-left-radius: 3px;
  background: #f47530;
}
.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid #8d5a20;
  border-right: 6px solid transparent;
}
.ribbon2:before,
.ribbon2:after {
  content: '';
  position: absolute;
}
.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
  border-left: 30px solid #f47530;
  border-right: 30px solid #f47530;
  border-bottom: 30px solid transparent;
}
.ribbon3 {
  width: 150px;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  position: absolute;
  left: -8px;
  top: 20px;
  background: #59324c;
}
.ribbon3:before,
.ribbon3:after {
  content: '';
  position: absolute;
}
.ribbon3:before {
  height: 0;
  width: 0;
  top: -8.5px;
  left: 0.1px;
  border-bottom: 9px solid black;
  border-left: 9px solid transparent;
}
.ribbon3:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 15px solid #59324c;
}

.ribbon-simple {
  position: absolute;
  top: 15px;
  left: -7px;
  padding: 1px 13px;
  border-radius: 13px;
  /* background: var(--accent); */
  background: red;
  /* box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5); */
  color: white;
}

.ribbon4 {
  position: absolute;
  /* min-width: 100px; */
  top: 15px;
  left: -0.5px;
  padding: 1px 13px;
  /* background: var(--accent); */
  background: red;
  box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);
  color: white;
}

.ribbon4:before,
.ribbon4:after {
  content: '';
  position: absolute;
}
.ribbon4:before {
  width: 7px;
  height: calc(100% + 7px);
  top: 0;
  left: -6.5px;
  padding: 0 0 7px;
  background: inherit;
  border-radius: 5px 0 0 5px;
}

/* .ribbon4:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 15px solid #59324c;
} */

.ribbon4:after {
  width: 5px;
  height: 5px;
  bottom: -5px;
  left: -4.5px;
  background: rgb(9, 19, 77);
  border-radius: 5px 0 0 5px;
}
.ribbon5 {
  display: block;
  width: calc(100% + 20px);
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-left: -10px;
  margin-right: -10px;
  background: #edba19;
  position: relative;
  top: 20px;
}
.ribbon5:before,
.ribbon5:after {
  content: '';
  position: absolute;
}
.ribbon5:before {
  height: 0;
  width: 0;
  bottom: -10px;
  left: 0;
  border-top: 10px solid #cd8d11;
  border-left: 10px solid transparent;
}
.ribbon5:after {
  height: 0;
  width: 0;
  right: 0;
  bottom: -10px;
  border-top: 10px solid #cd8d11;
  border-right: 10px solid transparent;
}
.wrap {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.wrap:before,
.wrap:after {
  content: '';
  position: absolute;
}
.wrap:before {
  width: 40px;
  height: 8px;
  right: 100px;
  background: #4d6530;
  border-radius: 8px 8px 0px 0px;
}
.wrap:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 100px;
  background: #4d6530;
  border-radius: 0px 8px 8px 0px;
}
.ribbon6 {
  width: 200px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed;
  box-shadow: 0 0 0 3px #57dd43, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
  background: #57dd43;
  text-align: center;
}
@media (min-width: 500px) {
  .ribbons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ribbon {
    width: 48%;
  }
}
