.assign-button {
  height: auto;
  padding: 5px 9px;
  border: none;
  border-radius: 8px;
  color: white;
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));
}

.increment-beat-button {
  color: rgb(92, 37, 202);
  border: 1px solid rgb(92, 37, 202);
  background-color: white;
  border-radius: 0;
  height: 25px;
  width: 25px;
  margin: 2px;
  line-height: 18px;
}
