.main-container {
  /* display: inline-flex; */
  height: 100%;
  width: inherit;
  margin-right: 5px;
  margin-left: 7px;
  white-space: normal;
  /* display: flex;
  flex-direction: column; */
  float: left;
  position: relative;
  padding-bottom: 10px;
  /* overflow: visible; */
  /* background-color: white; */
  /* box-sizing: border-box; */
}

.sub-main-container {
  height: 100%;
  width: 100%;
  padding-right: 20px;
}

/* .main-container img {
  height: 160px;
  object-fit: cover;
  width: 100%;

  border-radius: 8px;
  cursor: pointer;
} */

.video-info {
  flex-grow: 1;
}

.title-div {
  position: absolute;
  bottom: 0;
  background: none;
  /* display: flex;
  flex-direction: column; */
  width: 300px;
}

.title-line {
  z-index: 9;
  /* background: rgba(0, 0, 0, 0.69); */
  /* width: auto; */
  width: fit-content;
  padding-right: 30px;
  background-image: linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  /* linear-gradient(black, red) */
  /* display: block; */
  cursor: pointer;
}

.x-button {
  font-size: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  height: 40px;
  width: 40px;
  /* display: none; */
  transition: all 0.3s ease;
  opacity: 0;
}

.exit-icon {
  font-size: 3rem;
  position: absolute;
  top: 0;
  right: 0;
}

.main-container:hover .x-button {
  cursor: pointer;
  opacity: 1;
  /* display: block; */
}

.duration-label {
  /* display: flex; */
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
  padding: 0 3px;
  border-radius: 5px;
  background-color: #00000088;
  /* opacity: 1; */

  font-size: small;
  float: right;
  margin: 5px;
  color: white;
}

.date-container {
  color: var(--text-lighter-gray);
  font-size: 13px;
}
