.label-and-slider-container {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  margin: 10px;
  /* height: 80px; */
  /* paddin  */

  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'label1 slider1'
    'label2 slider12';
}

.slider-labels {
  /* margin: 10px; */
  /* position: relative; */
  /* top: 10px; */
}

.option-heading {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.save-cancel-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: 0;
}

.change-AB-button {
  width: 50%;
  height: 100%;
  background: none;
  border: 1px solid white;
  color: white;
}
