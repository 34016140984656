/* outermost row */
.visual-selection-container {
  color: black;
  margin: 0px -10px 0px -10px;
  border-top: 1px solid rgb(223, 221, 221);
}

.visual-selection-container h1 {
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 23px;
}

.videos-expanded-vertically {
  width: 150px;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.video-box-container {
  color: white;
  /* border-bottom: 1px solid rgb(223, 221, 221); */
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-box-container > a {
  scroll-snap-align: center;
}

.contain-the-scrollable {
  position: relative;
  width: 100%;
  /* height: 100%; */
  /* height: fit-content; */
  /* flex-grow: 1; */
}

.practice-contain-all {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-light-gray);
  /* justify-content: ; */
}

.practice-heading {
  /* color: var(--text-light-gray); */
  color: inherit;
  font-size: 24px;
  /* height: 100px; */
  position: relative;

  /* left: -15px; */
}

.heading-practice-list {
  min-width: 290px;
  width: 320px;
  max-width: 370px;
  text-align: center;
  flex-grow: 1;
}

.heading-duration {
  width: 34px;
  max-width: 65px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  /* justify-content: center; */
  align-items: center;
}

.contain-extra-options {
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: end; */
  height: 50px;
  gap: 5px;
  /* padding-inline: 15px; */
  border: 1px solid;
}

.heading-duration-inside {
  /* width: 50px; */
  min-width: 50px;
  height: 100%;
  /* height: ; */
  /* aspect-ratio: 1; */
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -3px;
  position: relative;
  top: -3px;

  /* color: var(--main-purple-no-transparancy);
  background-color: rgb(241, 232, 255); */
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 3px;

  /* width: 320px;
  min-width: 290px;
  max-width: 370px; */
}

.nav-buttons-placeholder {
  min-height: 100px;
  flex-basis: 200px;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.practice-summary-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* min-height: 45px;
  flex-basis: 80px;
  flex-shrink: 1; */
  height: 60px;
  min-height: 60px;
  color: var(--text-light-gray);
  /* color: green; */
  /* flex-grow: 1; */

  /* margin-bottom: 4%; */
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  background-size: 100% 200%; */
}

.hamburger-menu-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: inherit;
}
.change-view-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: inherit;
  margin: 10px;
  /* font-size: 40px; */
}

.none-sections-text {
  font-size: 18px;
  color: black;
  margin: 0 25px;
}

.practice-card-skeleton-container {
  /* height: 100%;
  width: 300px;
  background-color: white; */
}

.add-goal {
  background-color: blue;
  color: white;
  border-radius: 25px;
  min-width: 80px;
}
