.header {
  width: 100%;
  /* height: 10%;
  min-height: 3rem;
  max-height: 7rem; */
  height: 50px;
  min-height: 50px;
  /* min-width: 50px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: white; */
  /* box-shadow: 0 2px 14px 0 rgb(207, 207, 207); */
  background-color: var(--background-light-gray);
  backdrop-filter: blur(2px);
  /* background: linear-gradient(
    15deg,
    rgb(178, 69, 178),
    var(--videoButtonColor)
  ); */
  /* background-image: var(--background-gradient);
  background-size: 100vw 100vh; */
  border-bottom: 1px solid rgb(197, 197, 197);
  color: white;
  /* padding: 0 10%; */
  padding: 0 5px;
  z-index: 55;
}

.header h1 {
  font-size: 1.25rem;
}

.header ul[role='listbox'] {
  /* display: flex; */
  list-style: none;
  /* margin-left: 10px; */
  /* background-color: #2c2922; */
  border-radius: 0 0 14px 14px;
  padding: 0;
  align-items: center;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
}

.header a:hover,
.header a:active {
  color: #b864da;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.profile-accessor {
  font-size: 1.25rem;
  /* background-color: #ffbb00; */
  border-radius: 50%;
  /* border: 1px solid #ffbb00; */
  /* padding: 0.5rem 1.5rem; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  color: #2c2922;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.logout-button:hover,
.logout-button:active {
  background-color: #ffa600;
  border-color: #ffa600;
  border-radius: 100px;
}

.add-video-button {
  font-size: 46px;
  color: var(--main-purple-no-transparancy);
  height: 100%;
  /* line-height: normal; */
  padding: 0px, 10px;
  margin-top: 2px;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 60px;
  align-items: center; */
}

.home-button {
  padding-right: 10px;
  color: white;
  background: none;
  border: none;
  font-size: 2rem;
  display: inline-block;
  cursor: pointer;
}

.search-bar-container {
  /* min-height: 50px; */

  /* background-color: white; var(--background-light-gray); */
  overflow: visible;
  z-index: 100;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-bar {
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  width: 100%;
  /* height: 200px; */
  /* border-bottom: 1px solid var(--text-dark-gray); */

  flex-grow: 1;
}

@media (max-width: 800px) {
  /* … */
  .header {
    /* padding: 0 15px; */
    /* height: 2rem; */
  }

  .logout-button {
    font-size: 0.75rem;
  }
  .header a,
  h1 {
    font-size: 1.25rem;
  }

  .header li {
    margin: 0 0rem;
  }

  /* .greet-user {
    display: none;
  } */
  /* .header h1 {
    font-size: 1rem;
  } */
}

.left-side-header {
  /* position: absolute;
  top: 0;
  left: 0; */
  /* height: 100%; */
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.file-size-bar-container {
  /* height: px; */
}
