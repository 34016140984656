.rec.rec-arrow {
  /* border-radius: 10%; */

  transform: translateY(-37px);
  color: white;
  background-color: var(--navButtonColor);
  box-shadow: none;
  margin: 8px;
  z-index: 30;
}

.arrow-container {
  position: relative;
  width: 30px;
}

.pagination-arrow {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 50%;
  color: var(--text-dark-gray);
  /* color: black; */
  background-color: #ffffff99;
  /* background-color: var(--background-light-gray); */
  box-shadow: 2px 1px 14px 0 rgba(89, 89, 89, 0.3);
  z-index: 30;
  /* position: relative; */
  position: absolute;
  top: 70px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-arrow-practice-list {
  top: 50%;
}

.left-arrow {
  left: 0;
}
.right-arrow {
  right: 0;
}

.rec.rec-arrow:disabled {
  background-color: var(--navButtonDisabledColor);
}

.rec.rec-arrow:hover:enabled {
  background-color: var(--navButtonColor);
  box-shadow: 0 0 0px 12px var(--navButtonDisabledColor);
}

.rec.rec-arrow:active:focus {
  background-color: var(--navButtonColor);
}

.rec.rec-arrow:focus:enabled {
  background-color: var(--navButtonColor);
}

.rec.rec-arrow:focus:hover {
  /* background-color: var(--navButtonColor); */
  box-shadow: 0 0 0px 12px var(--navButtonDisabledColor);
}
/* .rec.rec-arrow  */
/* .rec.rec-arrow:hover {
  border-radius: 10%;
} */
/* 
.dYgkhw {
  margin: 0 30px;
} */

/* when side margin is greater than 0 we see a peek of the next one */
.rec.rec-slider-container {
  overflow: visible;
  /* margin: 0 10px; */
  margin: 0;
}
