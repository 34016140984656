.outer-container {
  width: 100%;
  margin: 0 3%;
  max-width: 400px;
  height: 45px;
  /* background-color: var(--main-purple-no-transparancy); */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items:  ; */
  align-items: flex-end;
  /* gap: 20px; */
}

.header-buttons {
  color: black;
}

.tab {
  color: var(--text-light-gray);
  /* color: var(--main-purple-no-transparancy); */
  position: relative;
  /* top: 3px; */
  height: 90%;
  /* width: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.tab[highlight='true'] {
  color: var(--main-purple-no-transparancy);
  /* font-weight: bold; */
}

.underline-tab {
  position: absolute;
  bottom: -5px;
  height: 4px;
  /* border-radius: 3px 3px 0 0; */
  width: calc(100% + 16px);
  background-color: var(--main-purple-no-transparancy);
}

.tab-badge-container {
  position: absolute;
  top: -2px;
  right: -10px;
}
