.search-thumb {
  height: 100%;
  /* height: 65px; */
  width: 45px;
  margin-right: 5px;
  /* object-fit: cover; */
}

.search-thumb > img {
  height: 100%;
  width: 45px;
  object-fit: cover;
}

.search-result-container {
  cursor: pointer;
}
.search-result-container:hover {
  background-color: rgba(30, 143, 255, 0.3);
  /* background-color: #ff006633; */
}

.search-result {
  width: 100%;
  padding: 10px 10px;
  /* margin-inline: 10px; */
  /* box-sizing: border-box; */
}

.search-result-grid {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  grid-row: auto auto;
  /* grid-column-gap: 20px;
  grid-row-gap: 20px; */
}

.result-container {
  padding: 10px;

  /* background-color: rgb(242, 223, 255); */
}

.empty-grid-space {
  width: 0px;
}

.title-and-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title-line {
  /* color: rgb(149, 149, 149); */
  color: black;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 70px;
  overflow: hidden;
}

.check-box-search-result {
  height: 100%;
  width: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.show-all-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
