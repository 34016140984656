.background {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));
  position: absolute;
}

.auth {
  margin: 5rem auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  width: 90%;
  border-radius: 5px;
  padding: 1rem;
  /* text-align: center; */
  background-color: white;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-elements {
  width: 90%;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #616161;
  /* text-transform: uppercase; */
  margin-bottom: 0.5rem;
}

.control input {
  display: block;
  width: 100%;
  margin: auto;
  border-radius: 4px;
  /* padding: 0.25rem; */
  border: 1px solid rgb(33, 26, 26);
}

.error-text {
  color: #b40e0e;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button {
  border: none;
  /* border-radius: 50%; */
  color: white;
  width: 60%;
  height: 36px;
  border-radius: 18px;
  margin: 20px 0;
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
