.Control-Buttons {
  /* margin: 3rem 0; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-grp {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hide-button {
  padding: 15px;
  color: white;
  font-size: 24px;
}

.btn {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 10vw;
  height: 5vh;
  border-radius: 14px;
  margin: 0px 6px;
  display: flex;
  border: 2px solid #e42a2a;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #f5f5f5;
}

.btn-one {
  background-color: #e42a2a;
}
