.outer-log-container {
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.timer-report-heading {
  font-size: 26px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.single-log-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.log-label {
  width: 100px;
  flex-grow: 1;
  text-align: right;
  color: var(--text-lighter-gray);
}

.log-time {
  width: 100px;
  flex-grow: 1;
  text-align: left;
}

.timed-section-title-container {
  position: absolute;
  left: 8px;
  top: 50%;
  width: calc(50% - 50px);
  transform: translateY(-50%);
  color: white;
  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.timed-section-title {
  width: 100%;
  max-width: 300px;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* text-align: center; */
}

.clear-timers-button-container {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.clear-timers-button-container div {
  background-color: var(--main-purple-no-transparancy);
  color: white;
  font-size: 20px;
  padding: 5px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.top-timer-buttons-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.finish-timer-log {
  color: white;
  background-color: #42c16f;
  /* background-color: rgb(84, 107, 254); */
  padding: 3px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pause-container-timeruptop {
  background-color: rgba(255, 255, 255, 0.264);
  border-radius: 50%;
  width: 30px;
  aspect-ratio: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
