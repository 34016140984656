.flex-container {
  padding: 10px 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 920;
  /* transition: all 0.8s; */
  pointer-events: none;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  /* height: 200px; */
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  min-height: 50px;
  margin-bottom: 5px;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  /* transition: transform 0.8s; */
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.fliiip {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.emoticon-div {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

/* Style the back side */
.flip-card-back {
  /* background-color: dodgerblue;
  color: white; */
  color: dodgerblue;

  /* font-weight: bold; */
  transform: rotateY(180deg);
}

.test-radial-movement {
  width: 240%;
  height: 240%;
  /* background-color: blue; */
  position: absolute;
  /* transform: translate(-100px, -100px); */
  /* left: -100%; */

  background-image: radial-gradient(
    circle at 50% 50%,
    transparent 0%,
    transparent 100%
  );
}

.DEPRECATED {
  background-image: radial-gradient(
    circle at 50% 50%,
    transparent 0%,
    /* transparent 15%,
    /* #1e90ff88 30%,
    #1e90ff88 100%  */ #00000088 20%,
    #00000088 100%
  );
}
