.AAcarousel-item {
  width: 10%; /* Adjust the width as needed */
  padding: 0 10px; /* Optional: Add padding for spacing between items */
  /* right: 0; */
}

.rec-carousel-item {
  /* max-width: 400px; */
}

/* .rec.rec-slider {
  right: 0;
} */

/* .rec-item-wrapper {
  width: 200px;
} */
.rec-item-wrapper {
  /* max-width: 400px; */
}

.practice .rec.rec-carousel-wrapper {
  /* height: 610px; */
  max-height: none;
  flex-grow: 1;
}

.add-first-section-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 80%;
  max-width: 500px;
}

.practice-thesis {
  font-size: 22px;
  color: var(--text-dark-gray);
  font-weight: bold;
  text-align: center;
}

.active-render-tab {
  /* border-bottom: 2px solid var(--text-light-gray); */
  background-color: rgba(111, 0, 255, 0.111);
  /* color: var(--main-purple-no-transparancy); */
  /* font-weight: bold; */
  /* font-size: 22px; */
  /* box-shadow: -1px -1px 3px 0px rgba(0, 0, 0, 0.15),
    1px -1px 3px 0px rgba(0, 0, 0, 0.524); */
  /* text-decoration: underline; */
}

.timer-on- {
  background-color: blue;
  color: white;
}

.timer-on-top {
  width: 100%;
  height: 0;
  transition: height 0.3s ease-out;
  position: relative;
  background-color: var(--main-purple-no-transparancy);
  /* background-color: blue; */
}
.extend-stopwatch-element {
  height: 40px;
}

.demo-container {
  transform: scale(0.7);
  display: flex;
  flex-direction: row;
  position: relative;
  top: -30px;
  left: -20px;
}
