.App {
  text-align: center;
}
.video_container {
  /* position: absolute; */
  /* height: 100px; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width: 130%; */
  min-width: 40vw;

  flex-grow: 1;
}

.container-video-separate {
  flex-grow: 1;
  flex-shrink: 4;
  height: 50%;
}

.player__wrapper {
  position: relative;
  /* top: 0; */
  /* height: 400px; */
  height: 100%;
  border-radius: 13px 13px 0 0;
  /* background-color: rgb(41, 41, 41); */
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));

  /* width: 10%; */
  /* height: 80%; */
  /* overflow: hidden; */
}

.player {
  /* border: 2px solid #7b2cbf;
    object-fit: fill;
    padding: 0;
    margin: 0; */
  position: relative;
  /* margin: 0; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

h2 {
  color: #7b2cbf;
}

.slowmo-button-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 24px;
  background-color: rgba(0, 0, 0, 0.667);
  z-index: 6;
}

.scanning-element {
  height: 3px;
  width: 100%;
  background: rgb(240, 178, 32);
  position: absolute;
}

.ripple-button-container {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  z-index: 8;
  height: 70px;
  width: 70px;
}
.button-ripple {
  position: relative;
  z-index: 2;
  height: 100%;

  width: 100%;
  border: none;
  border-radius: 50%;
  color: white;
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.expand-circle {
  z-index: 1;
  /* position: absolute; */
  position: absolute;
  top: 0%;
  /* left: 50%;  */
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #7b2cbf;
  /* z-index: 55; */
  animation: circle-expand 1s linear forwards;
}
/* 
.upload-video-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
} */

@keyframes circle-expand {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2.5);
  }
}

/* @keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
} */
