.topic-vid-container {
  /* width: 90px; */
  width: 100%;
  height: 100%;
  /* height: 90px; */
  aspect-ratio: 0.7;
  overflow: hidden;
  position: relative;
}

.topic-vid-container video {
  transform: scale(1.2);
}

.grayscale-image {
  filter: grayscale(100%) contrast(30%) brightness(130%);
  /* filter: brightness(90%); */
}

.completed-topic-vid {
  position: absolute;
  /* height: 100%;
  width: 100%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
