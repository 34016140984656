/* textarea.editable-text,
p { */
textarea.editable-text {
  border: none;
  /* background-color: blue; */
  width: 100%;
  /* height: 200px; */
  padding: 0;
  background: none;
  /* font-family: 'Courier New', Courier, monospace; */

  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
}

textarea.editable-text {
  position: absolute;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  resize: none;
  overflow: hidden;
  color: rgb(124, 147, 251);
}

.text-content-p {
  margin-bottom: 0.5rem;
  /* margin-top: 0.5rem; */
  color: red;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* ancient Opera */
  white-space: -o-pre-wrap; /* newer Opera */
  white-space: pre-wrap; /* Chrome; W3C standard */
  word-wrap: break-word; /* IE */
}

.status-message {
  position: absolute;
  bottom: -20px;
  right: 0;
  background-color: white;
  color: rgb(168, 168, 168);
}

.retry-send-button {
  /* background-color: red; */
  border-radius: 15px;
  border: 1px solid red;
  padding: 1px 5px;
  margin-left: 10px;
}
