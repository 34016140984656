.heading {
  /* background-color: var(--main-purple-no-transparancy);
  color: white;
  width: 100%;
  min-height: 40px; */
  font-size: 26px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.outer-container {
  width: 100%;
  height: 100%;
  /* padding: 25px; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-increments {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.time-increments input::placeholder {
  color: inherit;
  opacity: 0.6;
}
.time-increments input {
  border: none;
  width: 60px;
  font-size: 50px;
  /* color: var(--main-purple-no-transparancy); */
  color: var(--accent);
  /* text-align: center; */
}

.time-increments span {
  font-weight: bold;
  color: var(--text-light-gray);
}

.frequency-container {
  color: var(--text-light-gray);
  margin: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 22px;
}

.frequency-container input {
  /* font-size: 22px; */
  border: none;
  min-width: 20px;
  max-width: 30px;
  border-bottom: 1px solid black;
  margin: 0;
  /* color: var(--main-purple-no-transparancy); */
  color: var(--accent);
  text-align: center;
}

.frequency-container input::placeholder {
  color: inherit;
  opacity: 0.6;
}

.frequency-container select {
  /* border: 1px solid gray; */
  color: inherit;
  border-radius: 8px;
  /* color: gray; */
}

.button-container {
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.create-goal {
  /* background-color: var(--accent);
  color: white; */
  /* border-radius: 20px; */
  /* padding: 8px 15px; */
}

.cancel {
  color: var(--text-light-gray);
  font-weight: bold;
  cursor: pointer;
}
