.icon-n-title-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.video-title-block {
  color: black;
  font-size: small;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 7px;
}

.icon-img {
  height: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: red;
}
