.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  z-index: 20;
  /* background-color: rgba(0, 0, 0, 0.75); */
}

.modal {
  position: fixed;
  /* top: 10vh;
  bottom: 10vh; */
  /* margin: auto; */
  /* left: 5%;
  right: 5%; */
  left: 0;
  right: 0;
  top: 0;
  /* background-color: white; */
  background: none;
  /* padding: 1rem; */
  /* border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

/* .modal-fixed-height {
  top: 10vh;
  bottom: 10vh;
} */

/* .modal-flexible-height {
  top: 10vh;
  min-height: 10vh;
  /* max-height: 80vh; */
/* bottom: 10vh; */
/*} */

.exit-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

.content {
  height: 100%;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
