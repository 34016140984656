:root {
  --border-radius-search: 25px;
}

.kkMuiAutocomplete-root {
  /* width: 100%;
    max-width: 100%; */
  /* height: 100%; */

  background-color: white;
  flex-grow: 1;
  height: auto;
  overflow: visible;
  margin: 10px;
  border-radius: var(--border-radius-search);
  box-shadow: 0 1px 6px 0 rgb(190, 190, 190);
}

/* .MuiAutocomplete-root[open='true'] {
} */

.Mui-expanded {
  /* height: 100px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.MuiFormControl-root {
  /* height: 100%; */
}

.MuiAutocomplete-inputRoot {
  /* width: 100%; */
  border-radius: 0;
}

.MuiOutlinedInput-root {
  /* border-bottom: 3px solid red; */
  border-radius: 0;
}

.MuiInputBase-root {
  /* height: 100%; */
}

.MuiAutocomplete-input {
  /* background-color: green; */
}

.Mui-focused {
  /* background-color: blueviolet; */
}

.search-bar-ya .MuiOutlinedInput-notchedOutline[aria-hidden='true'] {
  /* border-color: blueviolet; */

  border: none;
}

.MuiAutocomplete-listbox {
  /* position: relative; */
}

.MuiPaper-root.MuiAutocomplete-paper {
  /* background-color: aqua; */

  /* border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--border-radius-search);
  border-bottom-right-radius: var(--border-radius-search); */
}

/* .MuiAutocomplete-groupUl {
  background-color: red;
  font-size: 55px;
} */
.MuiListSubheader-root.MuiAutocomplete-groupLabel {
  /* background-color: red; */
  /* font-size: 25px; */
  /* font-weight: bold; */
  color: var(--text-light-gray);
}

.MuiInputBase-input.MuiAutocomplete-input.MuiOutlinedInput-input {
  min-width: 11px;
}
