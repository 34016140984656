.form-components {
  align-self: flex-start;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-components label {
  margin-right: 5px;
  color: var(--text-light-gray);
  /* color: rgb(78, 160, 255); */
  /* font-weight: bold; */
  min-width: 50px;
  max-width: 76px;
}

.label-and-input {
  margin: 10px 0;
}
