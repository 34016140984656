.note-from-main-controls {
  position: absolute;

  left: 0;
  top: 0;
  right: 0;
  /* bottom: 0; */
  z-index: 2;
  margin: 0;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px 0px;
  /* resize: none; */
  /* background-color: 'none'; */
  /* color: white; */
}

textarea.note-from-main-controls {
  overflow: hidden;
  padding-left: 15px;
  padding-right: 60px;
  opacity: 1;
  bottom: 0;
  border: none;
  resize: none;
}

textarea.note-from-main-controls:focus,
input.note-from-main-controls:focus {
  outline: none;
}

.textarea-and-button-container {
  width: 100%;

  padding: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 503;
  pointer-events: auto;
}

.textarea-container {
  position: relative;
  flex-grow: 1;
  min-width: 70%;
  width: 100%;
  max-width: 500px;
  /* color: red; */
  padding: 10px;
  padding-left: 15px;
  padding-right: 60px;
  border-radius: 22px;
  background-color: #ffffff;
  color: #00000000;
  margin-bottom: 8px;
}

.note-button {
  height: 38px;
  /* width: 55px; */
  border-radius: 19px;
}
.save-note-button {
  /* position: absolute;
  bottom: -50px;
  right: 0; */

  color: white;
  background-color: var(--main-purple-no-transparancy);
  border: none;
  padding-inline: 10px;
  /* margin-left: 5px; */
}

.exit-note-x {
  background-color: transparent;
  color: var(--main-purple-no-transparancy);
  /* border: 1px solid var(--main-purple-no-transparancy); */
  border: none;
  aspect-ratio: 1;
}

.save-note-button-container {
  z-index: 6;
  /* align-self: flex-end; */
  /* margin-bottom: 7px; */
  margin: 3px;
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

/* .exit-note-x {
  color: var(--main-purple-no-transparancy);
} */

.save-note-button-fail {
  background-color: red;
}
