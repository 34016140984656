:root {
  --text-gray-2: rgb(154, 154, 154);
}

.practice-container-outer {
  height: 100%;
  /* transition: opacity 0.8s; */
  /* transition: transform 0.8s; */

  /* transform-style: preserve-3d; */
  /* height: 70vh; */
  /* visibility:visible */
}

.hidden.practice-container-outer {
  animation: card-out 0.3s forwards;
  /* visibility: hidden; */
  /* transform: rotateY(90deg); */
  /* transform: translateY(-200px);
  opacity: 0.3; */
}

@keyframes card-out {
  from {
    transform: translateY(0);
    opacity: 1;
    /* margin-left: 100%; */
    /* width: 300%; */
  }

  to {
    transform: translateY(-200px);
    opacity: 0;
    /* margin-left: 0%;
    width: 100%; */
  }
}

.swipe-in.practice-container-outer {
  animation: next-card-in 500ms forwards;
  /* visibility: hidden; */
  /* transform: rotateY(90deg); */
  /* transform: translateY(-200px);
  opacity: 0.3; */
}

@keyframes next-card-in {
  from {
    transform: translateX(100vw);
    opacity: 1;
    /* margin-left: 0%;
    width: 100%; */
  }
  to {
    transform: translateX(0);
    opacity: 1;
    /* margin-left: 100%; */
    /* width: 300%; */
  }
}

.practice-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  background-size: 100% 200%; */
}

.practice-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.practice-heading {
  font-size: 24px;
  height: 35px;
}

.practice-status-container {
  /* font-weight: bold; */
  font-size: 20px;
  height: 25px;
}

.practice-status-container [status='complete'] {
  color: green;
}

.practice-status-container [status='remaining'] {
  color: red;
}

.practice-notes {
  color: var(--text-gray-2);
}

.label-container-practice {
  /* display: inline-block; */
  color: var(--text-gray-2);
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label-in-practice-cards {
  font-size: 12px;
  /* display: inline-block; */
  min-width: 27px;
  border: 1px solid var(--text-gray-2);
  border-radius: 15px;
  padding: 2px 5px;
  margin: 4px 0;
}

.arrow-between-labels {
  display: inline-block;
  margin: 4px;
}

.filter-div-practice-card {
  /* -webkit-filter: grayscale(1); */
}

.filter-div-practice-cardl ::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  mix-blend-mode: saturation;
  /* -webkit-filter: invert(100%);
  filter: invert(100%); */
}

.filter-div-prsactice-card ::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  background-color: blue;
  /* mix-blend-mode: multiply; */
}

.play-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.completed-days-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* flex-direction: column; */
  justify-content: space-between;
  color: white;
}

.single-day-block {
  font-size: 2px;
  width: 20px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;

  /* margin: 2px;
  border-radius: 5px; */
}

:root {
  --day-gray: rgb(238, 238, 238);
  --day-green: rgb(7, 206, 0);
  --day-blue: rgb(78, 160, 255);
}

.single-day-block.default {
  background-color: var(--day-gray);
  color: transparent;
}

.single-day-block.complete {
  background-color: var(--day-green);
  /* color: transparent; */
}

.single-day-block.rest {
  /* background-color: rgb(206, 237, 206); */
  background: repeating-linear-gradient(
    45deg,
    var(--day-green),
    var(--day-green) 1px,
    rgb(238, 238, 238) 1px,
    rgb(238, 238, 238) 3px
  );
  color: transparent;
}
.single-day-block.current-day {
  background-color: var(--day-blue);
  /* color: transparent; */
}
