/* for all the bars */
.sectionContainer {
  overflow-y: auto;
  scrollbar-color: rebeccapurple green;
  padding-right: 1rem;

  position: relative;

  /* position: absolute; */
  /* top: 0;
  right: 0;
  bottom: 0; */
}

.video-and-section-container {
  display: flex;
  justify-content: center;
  /* height: 400px; */
  flex-grow: 1;
  position: relative;
}

.profile {
  margin: 5rem auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  width: 40rem;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  background-color: #f4f0fa;
}

.video-container {
  position: relative;
  width: 500px;
  margin: 0 auto;
  padding: 10px;
}
.video-container video {
  width: 100%;
  display: block;
}
.video-container:before {
  content: '';
  position: absolute;
  background: rgba(255, 28, 28, 0.5);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.eightCounts {
  position: absolute;
  top: 8%;
  color: white;
  background-color: rgba(0, 0, 0, 0.421);
  padding: 0 0.5rem;
  border-radius: 12px;
  font-size: 3rem;
  z-index: 7;
}

@media (max-width: 500px) {
  /* … */
  .sectionContainer {
    display: none;
  }
}
