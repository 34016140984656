.background {
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgb(95, 37, 95), rgb(92, 37, 202));
  /* position: absolute; */
  position: fixed;
  overflow: hidden;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 90%;
  top: -100px;
  left: 0;
  object-fit: cover;
  object-position: top left;
  mask-image: linear-gradient(#00000055, transparent);
  -webkit-mask-image: linear-gradient(#00000055, transparent);
  /* mask-image: linear-gradient(#000, transparent); */
  z-index: 1;
}

.just-loaded {
  animation: appear-img 2s ease-in;
}

@keyframes appear-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
