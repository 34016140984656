body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.calendar .title {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.date-section {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scroll {
  display: flex;
  overflow-x: auto;
  height: 150px;
  padding: 10px 0;
}

.scroll-container {
  display: flex;
  gap: 10px;
}

.date-card {
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  width: 30px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.date-card.selected {
  background-color: #6146c6;
}

.big {
  font-size: 20px;
  font-weight: bold;
}

.medium {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.large {
  font-size: 24px;
}

.text-white {
  color: #fff;
}
