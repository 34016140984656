.outer-widget {
  width: 100%;
  height: 100%;
  position: relative;
}

.widget-hamburger {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  color: inherit;
  /* color: white; */
  padding-top: 4px;
  line-height: 38px;
  font-size: 30px;
  z-index: 10;
  height: 28px;
  overflow: hidden;
}

.move-icon-container {
  /* background: rgba(124, 159, 59, 0.144); */
  position: absolute;
  /* top: 4px;
  left: 4px; */

  width: 100%;
  height: 100%;
  /* width: calc(100% - 8px);
  height: calc(100% - 8px); */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  z-index: 11;
  color: rgb(255, 255, 255);
  font-size: 40px;
  /* transition: background-color 0.2s; */
  opacity: 0;
  /* background-color: rgba(87, 87, 87, 0.457); */
  background-color: rgba(0, 98, 255, 0.164);
  transition: opacity 0.2s;
  /* background-color: transparent; */
  pointer-events: none;
  /* border: blue solid 2px; */
}

.unlocked-indv {
  /* transform: scale(0.5); */
  /* background-color: rgba(87, 87, 87, 0.457); */
  /* animation: blink 1s infinite alternate;  */
  /* width: calc(100% -10px); */
  /* padding: 7px; */

  opacity: 1;
  /* border: 3px dotted white; */
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23FFF' stroke-width='8' stroke-dasharray='17%2c 20' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px; */
}

.unlocked-indv_DEP:before,
.unlocked-indv_DEP:after {
  content: '';
  position: absolute;
  /* height: calc(100% + 10px);
  width: calc(100% * 2 - 10px); */
  height: calc(100% + 24px);
  width: calc(100% * 2 + 3px);
  /* top: -10px; */
  top: -12px;
  background-image: radial-gradient(
      circle at center,
      blue 4px,
      white 4px,
      white,
      6px,
      transparent 6px
    ),
    radial-gradient(
      circle at center,
      blue 4px,
      white 4px,
      white,
      6px,
      transparent 6px
    );
  background-size: 20px 20px;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
}

.unlocked-indv_DEP:after {
  right: 0px;
}

.unlocked-indv_DEP:before {
  left: 0px;
}

.white-border-box {
  border: 5px white solid;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
