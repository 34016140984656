.end-caps {
  width: 34px;
}

.stat-display-and-buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */
  /* gap: 10px; */
  border-radius: 17px;
  /* color: rgb(251, 255, 0); */
  /* color: rgb(187, 187, 187); */
  /* background-color: rgb(229, 229, 229); */
}

.practice-inc-dec {
  border-radius: 50%;
  /* aspect-ratio: 1; */
  height: 34px;
  /* background-color: rgba(0, 0, 0, 0.042); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-colors {
  color: rgb(150, 150, 150);
  border-color: rgb(150, 150, 150);

  /* background-color: rgb(238, 238, 238); */
}
.green-colors {
  color: rgb(66, 152, 0);
  background-color: rgba(38, 255, 59, 0.216);
}

.restart-count {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  /* opacity: 0.4; */
}
