.video_container-new-vid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eightCounts {
  position: absolute;
  top: 10px;
  color: white;
  text-shadow: rgb(33, 33, 33);
  /* background-color: rgba(0, 0, 0, 0.421); */
  padding: 0 0.5rem;
  border-radius: 12px;
  font-size: 25px;
  z-index: 7;
}

.annotate-buttons-question-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.annotate-buttons-question-container > button {
  border-radius: 6px;
  padding: 5px 10px;
}

.annotate-secondary {
  border: 1px solid rgb(92, 37, 202);
  color: rgb(92, 37, 202);
  background-color: white;
}

.yes-to-annotate-button {
  border: 1px solid rgb(92, 37, 202);
  background-color: rgb(92, 37, 202);
  color: white;
}

/* @keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
} */
