.bundle-wrapper[showChildren='false'] .font-box {
  /* display: none; */
  /* visibility: hidden; */
  opacity: 0;
  /* pointer-events: none; */
}

.bar-holder li:first-child {
  /* background-color: yellow;
  color: blue; */
  /* border-radius: 0; */
}

.bar-holder > li:not(:first-child):not(:last-child) :first-child {
  border-radius: 0;
  /* bottom: 0;
  top: 0; */
  height: inherit;
}
.bar-holder > li:last-child :first-child {
  border-radius: 0 0 15px 15px;
  top: 0;
}

.bundle-text {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */

  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
}
